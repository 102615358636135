import React from 'react';
import { node, arrayOf, string, object } from 'prop-types';
import Helmet from 'react-helmet';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Line from '../Line/Line';

import GlobalStyles from './GlobalStyles';
import Wrapper from './Wrapper';

function BaseLayout({ children, headerNav, page }) {
  return (
    <React.Fragment>
      <Helmet title="Karen Vogel | Psicoterapia" />
      <GlobalStyles />
      <Wrapper>
        <Header headerNav={headerNav} page={page} />
        <Line />
        {children}
      </Wrapper>
      <Footer />
    </React.Fragment>
  );
}

BaseLayout.propTypes = {
  children: node.isRequired,
  headerNav: arrayOf(object),
  page: string,
};

export default BaseLayout;
