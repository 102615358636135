import React from 'react';
import styled from 'styled-components';

import responsive from '../../helpers/responsive';

import Contact from './Contact';

const FooterWrapper = styled.footer`
  background-color: #d5d8d2;
  border-top: 1px solid #949692;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 12px;
  position: fixed;
  width: 100vw;

  ${responsive('600px', {
    position: 'relative',
    padding: '16px 12px 20px',
  })}
`;

function Footer() {
  //  const year = new Date().getUTCFullYear();

  return (
    <FooterWrapper id="contato">
      <Contact>
        <p>Atendimentos 100% online devido a COVID-19.</p>
        <p>Whatsapp: 11 99143.9655 | karen.vogel@me.com</p>
      </Contact>
    </FooterWrapper>
  );
}

export default Footer;
