import styled from 'styled-components';
import { Link } from 'gatsby';

import responsive from '../../../helpers/responsive';

const HeaderNavItem = styled(Link)`
  text-decoration: none;
  color: black;
  display: inline-block;
  padding: 3px 0;
  position: relative;
  font-size: 14px;

  ${responsive('600px', {
    display: 'none',
  })}

  ${({ active }) =>
    active &&
    `
      font-weight: 900;
      letter-spacing: 0.02em;
      
      &::before {
        content: '';
        height: 2px;
        background-color: #ff00ab;
        position: absolute;
        width: 36px;
        left: -46px;
        top: 45%;
      }
  `}
`;

export default HeaderNavItem;
