import React from 'react';
import styled from 'styled-components';

import responsive from '../../../../helpers/responsive';

import DownIcon from '../../../../assets/images/down-chevron.svg';

const Icon = styled.img`
  height: 12px;
  margin-left: 6px;
`;

const MobileTriggerButton = styled.button`
  align-self: center;
  align-items: center;
  appearance: none;
  border: 0;
  background-color: inherit;
  display: none;
  justify-content: center;
  margin-top: 36px;
  font-size: 18px;
  padding: 12px;
  box-shadow: 0 1px 0 0 #393b39;
  width: 100%;

  &:focus,
  &:active {
    outline: none;
  }

  ${responsive('600px', {
    display: 'inline-flex',
  })}
`;

const MobileTrigger = props => (
  <MobileTriggerButton {...props}>
    Menu <Icon src={DownIcon} />
  </MobileTriggerButton>
);

export default MobileTrigger;
