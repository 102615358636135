import styled from 'styled-components';
import { Link } from 'gatsby';

const MobileNavItem = styled(Link)`
  width: 100%;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  text-decoration: none;
  padding: 12px;
  text-transform: uppercase;
  color: #393b39;
`;

export default MobileNavItem;
