import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';

const GlobalStyles = createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'Roboto';
    src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap') format("truetype");
  }

  body {
    background-color: #D5D8D2;
    font-family: 'Roboto', sans-serif;

    * {
      box-sizing: border-box;
    }
  }
`;

export default GlobalStyles;
