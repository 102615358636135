import React from 'react';
import styled from 'styled-components';

import responsive from '../../../helpers/responsive';

import HeaderNavItem from './HeaderNavItem';
import MobileNavItem from './Mobile/MobileNavItem';
import MobilePane from './Mobile/MobilePane';
import MobileTrigger from './Mobile/MobileTrigger';

const HeaderNavWrapper = styled.nav`
  display: flex;
  justify-content: center;
  grid-column: 14 / 14;

  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 100px;
    text-align: right;
  }

  ${responsive('600px', {
    flexDirection: 'column',
  })}
`;

const HeaderNav = ({ headerNav, page }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <HeaderNavWrapper>
      <MobileTrigger onClick={() => setVisible(!visible)} />
      {visible && (
        <MobilePane>
          <ul>
            {headerNav.map(
              item =>
                item.node.title !== 'Home' && (
                  <li key={item.node.id}>
                    <MobileNavItem
                      to={`${item.node.path}`}
                      active={item.node.title === page}
                    >
                      {item.node.title}
                    </MobileNavItem>
                  </li>
                ),
            )}
            <MobileNavItem
              href="https://karenvogelcombr.wordpress.com/"
              as="a"
              target="_blank"
              active={false}
            >
              Blog
            </MobileNavItem>
          </ul>
        </MobilePane>
      )}
      <ul>
        {headerNav.map(
          item =>
            item.node.title !== 'Home' && (
              <li key={item.node.id}>
                <HeaderNavItem
                  to={`${item.node.path}`}
                  active={item.node.title === page}
                >
                  {item.node.title}
                </HeaderNavItem>
              </li>
            ),
        )}
        <HeaderNavItem
          href="https://karenvogelcombr.wordpress.com/"
          as="a"
          target="_blank"
          active={false}
        >
          Blog
        </HeaderNavItem>
      </ul>
    </HeaderNavWrapper>
  );
};

export default HeaderNav;
