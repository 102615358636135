import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import responsive from '../../helpers/responsive';
import Logo from '../../assets/images/karenvogel_logo.svg';

const HeaderLogoImg = styled.img.attrs({ src: Logo })`
  height: 120px;
  width: 240px;
  transition: all 60ms ease-in;

  &:hover {
    transform: scale(0.98);
  }

  ${responsive('600px', {
    width: '140px',
    height: '80px',
  })}
`;

const HeaderLogo = () => (
  <Link to="/">
    <HeaderLogoImg />
  </Link>
);

export default HeaderLogo;
