import styled from 'styled-components';

import responsive from '../../helpers/responsive';

const Contact = styled.p`
  color: #393b39;
  font-size: 14px;
  line-height: 1.6;
  display: flex;

  span {
    white-space: nowrap;
  }

  ${responsive('960px', {
    flexDirection: 'column',
    textAlign: 'center',
  })}
`;

export default Contact;
