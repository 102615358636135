import React from 'react';
import { arrayOf, object, string } from 'prop-types';
import styled from 'styled-components';

import responsive from '../../helpers/responsive';

import HeaderLogo from './HeaderLogo';
import HeaderNav from './HeaderNav/HeaderNav';

const HeaderWrapper = styled.header`
  grid-column: 4 / 14;
  display: flex;
  justify-content: space-between;

  ${responsive('600px', {
    gridColumn: '1 / 17',
    flexDirection: 'column',
    alignItems: 'center',
  })}

  ${responsive('960px', {
    gridColumn: '2 / 16',
  })}
`;

function Header({ headerNav, page }) {
  return (
    <HeaderWrapper>
      <HeaderLogo />
      {headerNav && <HeaderNav headerNav={headerNav} page={page} />}
    </HeaderWrapper>
  );
}

Header.propTypes = {
  headerNav: arrayOf(object),
  page: string,
};

Header.defaultProps = {
  headerNav: undefined,
  page: undefined,
};

export default Header;
