import styled from 'styled-components';

import responsive from '../../helpers/responsive';

const Line = styled.hr`
  align-self: center;
  background-color: white;
  border: 0;
  display: inline-block;
  grid-row: 2;
  grid-column: 4 / 14;
  height: 5px;
  margin: 24px 0 48px;
  width: 100%;

  ${responsive('600px', {
    margin: '12px 0 24px',
  })}

  ${responsive('960px', {
    gridColumn: '2 / 16',
  })}
`;

export default Line;
