import styled from 'styled-components';

import responsive from '../../helpers/responsive';

const Wrapper = styled.div`
  padding: 60px 0 100px;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-gap: 18px;

  ${responsive('600px', {
    gridGap: '12px',
    paddingTop: '30px',
  })}
`;

export default Wrapper;
