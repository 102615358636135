import styled from 'styled-components';

import responsive from '../../../../helpers/responsive';

const MobilePane = styled.div`
  background-color: white;
  border-top: 1px solid #393b39;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 194px;

  ul {
    width: 100%;
    justify-content: space-around;
  }

  ${responsive('600px', {
    display: 'inline-flex',
  })}
`;

export default MobilePane;
