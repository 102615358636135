import { css } from 'styled-components';

const responsive = (breakpoint, code) => {
  return css`
    @media screen and (max-width: ${breakpoint}) {
      ${code}
    }
  `;
};

export default responsive;

